html,
body {
  height: 100%;
  width: 100%;
}
body {
  background-color: #eee;
  position: relative;
  margin: 0;
}
.publishAllGamesButton {
  box-shadow: 0px 15px 0 20px #352d2d, 0px 40px 0 30px #000;
  cursor: pointer;
  background-color: #c0392b;
  border-radius: 50%;
  width: 480px;
  height: 80px;
  transform: translateX(-50%);
}
.publishAllGamesButton::before {
  content: '';
  z-index: 1;
  border-radius: 50%;
  background-color: #e74c3c;
  position: absolute;
  bottom: 100%;
  left: 0%;
  transition: bottom 0.4s;
  width: 480px;
  height: 80px;
}
.publishAllGamesButton::after {
  content: '';
  background-color: #c0392b;
  position: absolute;
  bottom: 50%;
  left: 0%;
  width: 480px;
  height: 80px;
  transition: height 0.4s;
}
.publishAllGamesButton:active::before {
  bottom: 10%;
}
.publishAllGamesButton:active::after {
  height: 10%;
}
